import './bootstrap';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function () {
    let followUsSlider = $('.follow-us .slider');

    followUsSlider.slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        prevArrow: null,
        nextArrow: null,
    });

    followUsSlider.on('click', '.follow-us .slick-slide', function (e) {
        e.preventDefault()
        var source = $(this).find('a').attr('href');
        $('video').attr('src', source);
        $('#imageModal').show();
    });

    $('#imageModal .close-button').click(function () {
        $('#imageModal').hide();
    });

    $('.follow-us .nav-prev').click(function () {
        followUsSlider.slick('slickPrev');
    });

    $('.follow-us .nav-next').click(function () {
        followUsSlider.slick('slickNext');
    });



    let complementFashionSlider = $('.complement-fashion .product-list .slider');
    complementFashionSlider.slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: $('.nav-prev'),
        nextArrow: $('.nav-next'),

    });

    $('.complement-fashion .nav-prev').click(function () {
        complementFashionSlider.slick('slickPrev');
    });

    $('.complement-fashion .nav-next').click(function () {
        complementFashionSlider.slick('slickNext');
    });


    $('.complement-fashion .slider').on('afterChange', function(event, slick, currentSlide, nextSlide) {
        if (currentSlide === 0) {
            $('.complement-fashion .nav-prev').addClass('disabled');
        } else {
            $('.complement-fashion .nav-prev').removeClass('disabled');
        }

        if (currentSlide === slick.slideCount - slick.options.slidesToShow) {
            $('.complement-fashion .nav-next').addClass('disabled');
        } else {
            $('.complement-fashion .nav-next').removeClass('disabled');
        }
    });

    let productSlider = $('.product-similar .product-list .slider');
    productSlider.slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: $('.nav-prev'),
        nextArrow: $('.nav-next'),
    });

    $('.product-similar .nav-prev').click(function () {
        productSlider.slick('slickPrev');
    });

    $('.product-similar .nav-next').click(function () {
        productSlider.slick('slickNext');
    });

    $('.product-similar .slider').on('afterChange', function(event, slick, currentSlide, nextSlide) {
        if (currentSlide === 0) {
            $('.product-similar .nav-prev').addClass('disabled');
        } else {
            $('.product-similar .nav-prev').removeClass('disabled');
        }

        if (currentSlide === slick.slideCount - slick.options.slidesToShow) {
            $('.product-similar .nav-next').addClass('disabled');
        } else {
            $('.product-similar .nav-next').removeClass('disabled');
        }
    });


});


$(document).ready(function () {
    const customDropdown = $(".custom-dropdown");
    const selectedOption = customDropdown.find(".selected-option");
    const arrowDown = customDropdown.find(".arrow-down");
    const optionsList = customDropdown.find(".options");

    selectedOption.on("click", function () {
        optionsList.toggle();
        arrowDown.css("transform", optionsList.is(":visible") ? "rotate(180deg)" : "rotate(0deg)");
    });

    optionsList.on("click", "li", function () {
        const selectedValue = $(this).data("value");
        console.log("Выбран фильтр: " + selectedValue);
        selectedOption.text($(this).text());
        optionsList.hide();
        arrowDown.css("transform", "rotate(0deg)");
    });

    $(document).on("click", function (event) {
        if (!customDropdown.is(event.target) && customDropdown.has(event.target).length === 0) {
            optionsList.hide();
            arrowDown.css("transform", "rotate(0deg)");
        }
    });

    selectedOption.on("keydown", function (event) {
        if (event.key === "Enter" || event.key === " ") {
            optionsList.toggle();
            arrowDown.css("transform", optionsList.is(":visible") ? "rotate(180deg)" : "rotate(0deg)");
        }
    });
});


$(document).ready(function() {
    if($('select.select2').length > 0) {
        $('select.select2').select2();

    }
});

$(document).ready(function () {
    if($('.product-view .fotorama').length > 0) {
        $('.product-view .fotorama').fotorama({
            nav: 'thumbs',
            thumbwidth: '84',
            thumbheight: '133',
            width: '100%',
            maxwidth: '100%',
            allowfullscreen: true,
        });
    }

});


$(document).ready(function () {
    $(".form-block").each(function() {
        setupFormBlock($(this));
        setupFormBlock2($(this));
    });
});


$(document).ready(function() {

    $('input[name="color"]').change(function() {
        var selectedColor = $('input[name="color"]:checked');
        var color_id = selectedColor.data('color-id');
        var product_id = selectedColor.data('product-id');

        $.ajax({
            type: 'GET',
            url: '/api/product/photo/change-color',
            data: {
                product_id: product_id,
                color_id: color_id
            },
            success: function(response) {
                $('.fotorama').data('fotorama').stopAutoplay();

                $('.fotorama').empty();

                response.data.forEach(function(photo) {
                    $('.fotorama').append('<img src="/storage/products/photo/' + photo.photo_detail + '" alt="">');
                });


                $('.fotorama').fotorama().data('fotorama', null).fotorama({
                    nav: 'thumbs',
                    thumbwidth: '84',
                    thumbheight: '133',
                    width: '100%',
                    maxwidth: '100%',
                    allowfullscreen: true,
                });
            },

            error: function(xhr, status, error) {
                console.error(xhr.responseText);
            }
        });

        return false;


    })


});


$(document).ready(function() {
    $('.add-to-cart').click(function(e) {
        e.preventDefault();
        var selectedSize = $('input[name="size"]:checked').val();
        var selectedColor = $('input[name="color"]:checked').val();
        var successMessage = $(this);

        var url = $(this).attr('href');

        if(selectedSize && selectedColor) {
            var addToCartUrl = url + '?size=' + selectedSize + '&color=' + selectedColor;

            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                success: function(response) {
                    successMessage.prop('disabled', true)
                    successMessage.html(response.success)
                    setTimeout(function() {
                        successMessage.text('додати в кошик')
                        successMessage.prop('disabled', false)
                    }, 2000);

                    getCartItemQty()
                },
                error: function(xhr, status, error) {
                    console.error(error);
                }
            });

        } else {
            alert('Пожалуйста, выберите размер и цвет перед добавлением в корзину.');
        }
    });
});


$(document).ready(function() {
    if($('input[name=phone]').length > 0) {
        $('input[name=phone]').mask('+380(99) 999-99-99');

    }

});

$(document).ready(function() {
    $('.sorting .options li').click(function() {
        let value = $(this).data('value');
        $('.selected-option').text($(this).text());
        $('input[name="order"]').val(value);
        $('.sorting').submit();

    });

});

$(document).ready(function() {
    if($('.follow-us .slider .slider-item .fotorama .video-link').length > 0) {
        $('.follow-us .slider .slider-item .fotorama .video-link').magnificPopup({
            type: 'iframe',
            iframe: {
                markup: '<div class="mfp-iframe-scaler">' +
                    '<div class="mfp-close"></div>' +
                    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                    '</div>',
            }
        });
    }

});
$(document).ready(function() {
    if($('#selectAddressFromTemplate').length > 0) {
        $('#selectAddressFromTemplate').change(function() {
            if ($(this).val() !== '') {
                $('#deliveryPlace').removeAttr('required');
                $('#deliveryPlace').prop('disabled', true);
                $('#deliveryDepartment').removeAttr('required');

            } else {
                $('#deliveryPlace').attr('required');
                $('#deliveryPlace').prop('disabled', false);
                $('#deliveryDepartment').attr('required');
            }
        });
    }
});

$(document).ready(function() {
    if($('.purchases__items__header').length > 0) {
        $('.purchases__items__header').click(function(e) {
            e.preventDefault()

            if($(this).find('.purchases__items__header__collapse i').hasClass('open')) {
                $('.purchases__items__header').find('.purchases__items__header__collapse i').removeClass('open');
                $(this).find('.purchases__items__header__collapse i').removeClass('open');

                $('.purchases__items__body').removeClass('open')
                $(this).parent().find('.purchases__items__body').removeClass('open')

            } else {
                $('.purchases__items__header').find('.purchases__items__header__collapse i').removeClass('open');
                $(this).find('.purchases__items__header__collapse i').addClass('open');

                $('.purchases__items__body').removeClass('open')
                $(this).parent().find('.purchases__items__body').addClass('open')

                var offsetTop = $(this).offset().top;

                $('html, body').animate({
                    scrollTop: offsetTop
                }, 500);


            }


        })

    }

});


let setupFormBlock = function($formBlock) {
    const $inputField = $formBlock.find(".inputField");
    const $decreaseButton = $formBlock.find(".decreaseButton");
    const $increaseButton = $formBlock.find(".increaseButton");

    $decreaseButton.on("click", function() {
        if ($inputField.val() > parseInt($inputField.attr("min"))) {
            $inputField.val(parseInt($inputField.val()) - 1);
            if (typeof $inputField.data("product-id") !== 'undefined') {
                updateCartItemQty($inputField.data("product-id"), $inputField.data("product-hash"), $inputField.val());
            }

        }
    });

    $increaseButton.on("click", function() {
        if ($inputField.val() < parseInt($inputField.attr("max"))) {
            $inputField.val(parseInt($inputField.val()) + 1);
            if (typeof $inputField.data("product-id") !== 'undefined') {
                updateCartItemQty($inputField.data("product-id"), $inputField.data("product-hash"), $inputField.val());
            }

        }
    });

    $inputField.on("input", function() {
        let inputValue = $inputField.val().replace(/\D/g, "");
        const maxInputValue = parseInt($inputField.attr("max"));

        if (inputValue > maxInputValue) {
            inputValue = maxInputValue;
        }

        $inputField.val(inputValue);
        if (typeof $inputField.data("product-id") !== 'undefined') {
            updateCartItemQty($inputField.data("product-id"), $inputField.data("product-hash"), $inputField.val());
        }

    });
}

let updateCartItemQty = function(productId, productHash, newQuantity) {
    $.ajax({
        url: "/ua/card/update-cart",
        method: "POST",
        data: {
            product_hash: productHash,
            product_id: productId,
            quantity: newQuantity
        },
        dataType: "json",
        success: function(response) {
            if (response.success) {
                var productElement = $(".totalProductSum[data-product-hash='" + productHash + "']");
                productElement.text(parseFloat(response.total_product_sum).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ','));
                $("#totalSum").text(parseFloat(response.total_sum).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ','));
            }

            getCartItemQty();
        },
        error: function(error) {
            console.log(error);
        }
    });
}

let updateCartItemQty2 = function(productId2, productHash2, newQuantity2) {
    $.ajax({
        url: "/ua/card/update-cart",
        method: "POST",
        data: {
            product_hash: productHash2,
            product_id: productId2,
            quantity: newQuantity2
        },
        dataType: "json",
        success: function(response) {
            if (response.success) {
                var productElement2 = $(".totalProductSum2[data-product-hash='" + productHash2 + "']");
                productElement2.text(parseFloat(response.total_product_sum).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ','));
                $("#totalSum2").text(parseFloat(response.total_sum).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ','));
            }

            getCartItemQty();
        },
        error: function(error) {
            console.log(error);
        }
    });
}

let setupFormBlock2 = function($formBlock2) {
    const $inputField2 = $formBlock2.find(".inputField2");
    const $decreaseButton2 = $formBlock2.find(".decreaseButton2");
    const $increaseButton2 = $formBlock2.find(".increaseButton2");

    $decreaseButton2.on("click", function() {
        if ($inputField2.val() > parseInt($inputField2.attr("min"))) {
            $inputField2.val(parseInt($inputField2.val()) - 1);
            if (typeof $inputField2.data("product-id") !== 'undefined') {
                updateCartItemQty2($inputField2.data("product-id"), $inputField2.data("product-hash"), $inputField2.val());
            }

        }
    });

    $increaseButton2.on("click", function() {
        if ($inputField2.val() < parseInt($inputField2.attr("max"))) {
            $inputField2.val(parseInt($inputField2.val()) + 1);
            if (typeof $inputField2.data("product-id") !== 'undefined') {
                updateCartItemQty2($inputField2.data("product-id"), $inputField2.data("product-hash"), $inputField2.val());
            }

        }
    });

    $inputField2.on("input", function() {
        let inputValue2 = $inputField2.val().replace(/\D/g, "");
        const maxInputValue2 = parseInt($inputField2.attr("max"));

        if (inputValue2 > maxInputValue2) {
            inputValue2 = maxInputValue2;
        }

        $inputField2.val(inputValue2);
        if (typeof $inputField2.data("product-id") !== 'undefined') {
            updateCartItemQty2($inputField2.data("product-id"), $inputField2.data("product-hash"), $inputField2.val());
        }

    });
}


const getCartItemQty = function () {
    $.ajax({
        url: "/ua/get-basket-qty",
        method: "GET",
        dataType: "json",
        success: function(response) {
            if (response.success) {
                $("#totalQty").text(response.total_qty);
            }
        },
        error: function(error) {
            console.log(error);
        }
    });
}

$(document).ready(function() {
    var successMessage = $('#successMessage');
    if (successMessage.length) {
        setTimeout(function() {
            successMessage.hide();
        }, 3000);
    }
});